export default {
  group: [
    {
      name: 'personal_data',
      fields: [
        {
          name: 'full_name',
          type: 'text',
          column_type: 'full-row',
        },
        {
          name: 'department',
          type: 'text',
          column_type: 'full-row',
        },
        {
          name: 'position',
          type: 'text',
          column_type: 'full-row',
        },
      ],
    },
    {
      name: 'contact_data',
      fields: [
        {
          name: 'working_phone_number',
          type: 'text',
          column_type: 'one-third-row',
        },
        {
          name: 'working_email',
          type: 'text',
          column_type: 'one-third-row',
        },
      ],
    },
  ],
};
